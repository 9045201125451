<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <v-main>
      <RouterView />
    </v-main>
  </v-app>
</template>

<script>
export default {
  props: {
    loggedIn: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
  },
};
</script>
<style scoped></style>
