<template>
  <component :is="layout()" :logged-in="loggedIn" />
</template>

<script>
import DefaultLayout from '@/components/layout/DefaultLayout.vue';

export default {
  components: {
    DefaultLayout,
  },
  data: () => ({
    loggedIn: false,
  }),
  methods: {
    layout() {
      // レイアウトをルートのmeta.layoutで切り分ける & ログイン状態の保持
      const layout = this.$route.meta.layout ? this.$route.meta.layout + '-layout' : 'default-layout';
      return layout;
    },
  },
};
</script>
