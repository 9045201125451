import Vue from 'vue';
import Router from 'vue-router';
import Index from '@/views/Index.vue';
import Login from '@/views/Login.vue';

Vue.use(Router);
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      components: { default: Index },
      meta: { isPublic: true },
    },
    {
      path: '/login',
      name: 'login',
      components: { default: Login },
      meta: { isPublic: true },
    },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});
export default router;
