import Vue from 'vue';
// メッセージ関係
import App from '@/App.vue';
import router from '@/router';
import vuetify from '@/plugins/vuetify';

new Vue({
  vuetify,
  router,
  render: (h: Function) => h(App),
}).$mount('#app');
