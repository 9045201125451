import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import ja from 'vuetify/src/locale/ja';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  lang: {
    locales: { ja },
    current: 'ja',
  },
  theme: {
    themes: {
      light: {
        primary: 'light-blue lighten-1',
        secondary: colors.shades.white,
        accent: '#E9E9E9',
        error: '#df3f5b',
        background: 'white',
        anchor: '#039BE5',
      },
      dark: {
        primary: '#light-blue lighten-1',
      },
    },
  },
});
